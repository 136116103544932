import http from "../../http-common";
import httpMultipart from "../../http-multipart";
import http_multipart from "@/http-multipart";

class SystemConfigService {
    /**
     * mẫu điểm danh
     */
    findAllAttendanceSample() {
        return http.get(`/master/system-config/attendance-sample`);
    }

    createAttendanceSample(data) {
        return http.post(`/master/system-config/attendance-sample`, data);
    }

    updateAttendanceSample(data) {
        return http.put(`/master/system-config/attendance-sample`, data);
    }

    deleteAttendanceSampleOne(id) {
        return http.delete(`/master/system-config/attendance-sample/${id}`);
    }

    //mau loi chuc
    findAllWishesSample() {
        return http.get(`/master/system-config/wishes-sample`);
    }

    createWishesSample(data) {
        return httpMultipart.post("/master/system-config/wishes-sample", data);
    }

    updateWishesSample(data) {
        return httpMultipart.post("/master/system-config/wishes-sample/update", data);
    }

    deleteWishesSample(id) {
        return http.delete(`/master/system-config/wishes-sample/${id}`);
    }

    /**
     * mẫu nhận xét
     */
    findAllEvaluateSample() {
        return http.get(`/master/system-config/evaluate-sample`);
    }

    createEvaluateSample(data) {
        return http.post(`/master/system-config/evaluate-sample`, data);
    }

    updateEvaluateSample(data) {
        return http.put(`/master/system-config/evaluate-sample`, data);
    }

    deleteEvaluateSampleOne(id) {
        return http.delete(`/master/system-config/evaluate-sample/${id}`);
    }

    /**
     * thiết lập tiêu đề, nội dung
     */
    findAllWebSystemTitle() {
        return http.get(`/master/system-config/web-system-title`);
    }

    updateWebSystemTitle(data) {
        return http.put(`/master/system-config/web-system-title`, data);
    }

    /**
     * thiết lập hệ thống
     */
    findAllAppVersion() {
        return http.get(`/master/system-config/system/app-version`);
    }

    updateAppVersion(data) {
        return http.put(`/master/system-config/system/app-version`, data);
    }

    findSysInfor() {
        return http.get(`/master/system-config/system/infor`);
    }

    updateSysInfor(data) {
        return http.put(`/master/system-config/system/infor`, data);
    }

    findSchoolConfig(idAgent, schoolName,schoolActive) {
        return http.get(`/master/system-config/school?idAgent=${idAgent}&schoolName=${schoolName}&schoolActive=${schoolActive}`);
    }

    findSchoolIconParent(id) {
        return http.get(`/master/system-config/school/icon-parent/${id}`);
    }

    updateSchoolIconParent(id, data) {
        return http.put(`/master/system-config/school/icon-parent/${id}`, data);
    }

    findSchoolIconTeacher(id) {
        return http.get(`/master/system-config/school/icon-teacher/${id}`);
    }

    updateSchoolIconTeacher(id, data) {
        return http.put(`/master/system-config/school/icon-teacher/${id}`, data);
    }

    findSchoolIconPlus(id) {
        return http.get(`/master/system-config/school/icon-plus/${id}`);
    }

    updateSchoolIconPlus(id, data) {
        return http.put(`/master/system-config/school/icon-plus/${id}`, data);
    }

    findSchoolConfigSystem(id) {
        return http.get(`/master/system-config/school/config/${id}`);
    }

    updateSchoolConfigSystem(data) {
        return http.put(`/master/system-config/school/config`, data);
    }

    /**
     * cấu hình khác
     */
    findAllBirthdaySample() {
        return http.get(`/master/system-config/birthday-sample`);
    }

    updateActiveBirthdaySample(data) {
        return http.put(`/master/system-config/birthday-sample/active`, data);
    }

    updateBirthdaySample(data) {
        return httpMultipart.post(`/master/system-config/birthday-sample/update`, data);
    }

    findAllCelebrateSample() {
        return http.get(`/master/system-config/celebrate-sample`);
    }

    updateActiveCelebrateSample(data) {
        return http.put(`/master/system-config/celebrate-sample/active`, data);
    }

    createCelebrateSample(data) {
        return httpMultipart.post("/master/system-config/celebrate-sample", data);
    }

    updateCelebrateSample(data) {
        return httpMultipart.post(`/master/system-config/celebrate-sample/update`, data);
    }

    deleteCelebrateSample(id) {
        return http.delete(`/master/system-config/celebrate-sample/${id}`);
    }

    getNotifyConfig(idSchool) {
        return http.get(`/master/system-config/config-notify/${idSchool}`);
    }

    updateNotifyConfig(data) {
        return http.put(`/master/system-config/config-notify`, data);
    }

    getIconLocConfig(idSchool) {
        return http.get(`/master/system-config/config-icon-lock/${idSchool}`);
    }

    updateIconLockConfig(data) {
        return http.put(`/master/system-config/config-icon-lock`, data);
    }

    //add new Role
    addNewRole(data) {
        return http.post(`/master/system-config/add-new`, data);
    }

    // lấy tất cả apiRole
    getAllApi() {
        return http.get(`/master/system-config/listRole`)
    }

    //xóa api role -cường
    deleteApiRole(id) {
        return http.delete(`/master/system-config/delete${id}`)
    }

    //Sửa api role  -cường
    updateApiRole(id, data) {
        return http.put(`/master/system-config/edit-role-api/${id}`, data)
    }

    getApi(type) {
        return http.get(`/master/system-config/api?type=${type}`);
    }

    // lấy 1 api có ranks và level1 cao nhất
    getMaxLevel1() {
        return http.get(`/master/system-config/get-max-level1`);
    }

    updateApi(data) {
        return http.put(`/master/system-config/api`, data);
    }

    updateApiRanks(data) {
        return http.put(`/master/system-config/api/ranks`, data);
    }

    //onecam
    getOneCamConfigService(id) {
        return http.get(`/master/system-config/onecam/config?idSchool=${id}`);
    }

    updateOneCamConfigService(data) {
        return http.put(`/master/system-config/onecam/config`, data);
    }


    /**
     * Template
     */

    /**
     * Tạo template mới
     */
    createTemplateApp(formData) {
        return http_multipart.post("/master/system-config/create", formData);
    }
    createTemplateAppSystem(formData) {
        return http_multipart.post("/master/system-config/createSample", formData);
    }
    /**
     * Lấy danh sách template theo loại app và trường
     */

    getListTemplate(idSchool, appType, nameTemplate, pageNumber, maxPageItem) {
        return http.get(`/master/system-config/getListTemplate?idSchool=${idSchool}&appType=${appType}&nameTemplate=${nameTemplate}&pageNumber=${pageNumber}&maxPageItem=${maxPageItem}`);
    }

    getListTemplateSystem(appType, nameTemplate, pageNumber, maxPageItem) {
        return http.get(`/master/system-config/getListTemplateSample?appType=${appType}&nameTemplate=${nameTemplate}&pageNumber=${pageNumber}&maxPageItem=${maxPageItem}`);
    }


    /**
     * Chi tiết Template
     */
    getDetailTemplate(idTemplate) {
        return http.get(`/master/system-config/getDetailTemplate?idTemplate=${idTemplate}`);
    }

    getDetailTemplateSystem(idTemplate) {
        return http.get(`/master/system-config/getDetailTemplateSample?idTemplate=${idTemplate}`);
    }
    /**
     * Xoá 1 Template
     */
    deleteOneTemplate(idTemplate) {
        return http.delete(`/master/system-config/delete?idTemplate=${idTemplate}`);
    }
    deleteOneTemplateSystem(idTemplate) {
        return http.delete(`/master/system-config/deleteSample?idTemplate=${idTemplate}`);
    }
    /**
     * Xoá 1 hoặc nhiều  Template
     */
    deleteOneOrManyTemplate(idTemplateList) {
        return http.delete(`/master/system-config/deleteManyTemplate?idTemplateList=${idTemplateList}`);
    }

    /**
     * Sửa Template
     */


    updateTemplate(formData) {
        return http_multipart.put("/master/system-config/update", formData);
    }
    updateTemplateSystem(formData) {
        return http_multipart.put("/master/system-config/updateSample", formData);
    }
    /**
     * Đặt trạng thái template mặc định
     */
    setDefaultTemplate(idTemplate) {
        return http.get(`/master/system-config/setDefaultTemplate?idTemplate=${idTemplate}`);
    }

    // Tắt sinh hóa đơn, khoản thu tự động
    turnOffAutomaticInvoiceGeneration(data){
        return http.post('/master/system-config/turnOffAutomaticInvoiceGeneration',data)
    }

}

export default new SystemConfigService();