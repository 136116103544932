var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleClickTab },
            model: {
              value: _vm.activeTabName,
              callback: function ($$v) {
                _vm.activeTabName = $$v
              },
              expression: "activeTabName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "Cấu hình chung", name: "sysInforTab" } },
              [
                _c("div", { staticClass: "wrapper-table" }, [
                  _c(
                    "table",
                    { staticClass: "table-data", attrs: { border: "" } },
                    [
                      _c("thead", { staticClass: "table-header" }, [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("STT"),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Nội dung thiết lập")]
                          ),
                          _c("td", { staticClass: "table-action" }, [
                            _vm._v("Giá trị thiết lập"),
                          ]),
                        ]),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("1"),
                          ]),
                          _c("td", [_vm._v("Tên số điện thoại 1")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.namePhone1,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "namePhone1",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.namePhone1",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("2"),
                          ]),
                          _c("td", [_vm._v("Số điện thoại 1")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.phone1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "phone1", $$v)
                                  },
                                  expression: "sysInforData.phone1",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("3"),
                          ]),
                          _c("td", [_vm._v("Tên số điện thoại 2")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.namePhone2,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "namePhone2",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.namePhone2",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("4"),
                          ]),
                          _c("td", [_vm._v("Số điện thoại 2")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.phone2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "phone2", $$v)
                                  },
                                  expression: "sysInforData.phone2",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("5"),
                          ]),
                          _c("td", [_vm._v("Tên số điện thoại 3")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.namePhone3,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "namePhone3",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.namePhone3",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("6"),
                          ]),
                          _c("td", [_vm._v("Số điện thoại 3")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.phone3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "phone3", $$v)
                                  },
                                  expression: "sysInforData.phone3",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("7"),
                          ]),
                          _c("td", [
                            _vm._v("Tỷ lệ nén ảnh trong Album theo chiều rộng"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.widthAlbum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "widthAlbum",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.widthAlbum",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("8"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Tỷ lệ nén ảnh các mục khách theo chiều rộng"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.widthOther,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "widthOther",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.widthOther",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("9"),
                          ]),
                          _c("td", [_vm._v("Link Api backend Web")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.urlApiBackend,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlApiBackend",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlApiBackend",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("10"),
                          ]),
                          _c("td", [_vm._v("Link Url lưu trữ trên Server")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.localUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "localUrl", $$v)
                                  },
                                  expression: "sysInforData.localUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("11"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Link Url đường dẫn lưu trữ sử dụng trong môi trường internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.webUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "webUrl", $$v)
                                  },
                                  expression: "sysInforData.webUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("12"),
                          ]),
                          _c("td", [
                            _vm._v("Số lượng khi phân trang cho mobile"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.mobileSizePage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "mobileSizePage",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.mobileSizePage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("13"),
                          ]),
                          _c("td", [
                            _vm._v("Url ảnh đại diện của OneKids trên local"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureSystemLocal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureSystemLocal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.urlPictureSystemLocal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("14"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện của OneKids trên internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureSystem,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureSystem",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlPictureSystem",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("15"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của nhà trường trên Local"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureSchoolLocal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureSchoolLocal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.urlPictureSchoolLocal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("16"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của nhà trường trên internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureSchool,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureSchool",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlPictureSchool",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("17"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của nhân viên trên Local"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value:
                                    _vm.sysInforData.urlPictureEmployeeLocal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureEmployeeLocal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.urlPictureEmployeeLocal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("18"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của nhân viên trên internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureEmployee,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureEmployee",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlPictureEmployee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("19"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của phụ Huynh trên Local"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureParentLocal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureParentLocal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.urlPictureParentLocal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("20"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của phụ huynh trên internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureParent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureParent",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlPictureParent",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("21"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của học sinh trên Local"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureKidsLocal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureKidsLocal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.urlPictureKidsLocal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("22"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Url ảnh đại diện mặc định của học sinh trên internet"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.sysInforData.urlPictureKids,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "urlPictureKids",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.urlPictureKids",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("23"),
                          ]),
                          _c("td", [_vm._v("Link thông tin điều khoản")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.policyLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "policyLink",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.policyLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("24"),
                          ]),
                          _c("td", [
                            _vm._v("Link hướng dẫn sử dụng cho phụ huynh"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.guideLink,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "guideLink", $$v)
                                  },
                                  expression: "sysInforData.guideLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("25"),
                          ]),
                          _c("td", [
                            _vm._v("Link hướng dẫn sử dụng cho giáo viên"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.guideTeacherLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "guideTeacherLink",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.guideTeacherLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("26"),
                          ]),
                          _c("td", [
                            _vm._v("Link hướng dẫn sử dụng cho nhà trường"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.guidePlusLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "guidePlusLink",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.guidePlusLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("27"),
                          ]),
                          _c("td", [_vm._v("Link hỗ trợ trực tuyến")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.supportLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "supportLink",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.supportLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("28"),
                          ]),
                          _c("td", [_vm._v("Link kênh Video OneKids")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.videoLink,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "videoLink", $$v)
                                  },
                                  expression: "sysInforData.videoLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("29"),
                          ]),
                          _c("td", [_vm._v("Thời gian gửi lời chúc")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-time-picker", {
                                staticClass: "input-data",
                                attrs: {
                                  clearable: false,
                                  type: "time",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  "picker-options": {
                                    selectableRange: "08:00:00 - 21:00:00",
                                  },
                                },
                                model: {
                                  value: _vm.sysInforData.timeSend,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sysInforData, "timeSend", $$v)
                                  },
                                  expression: "sysInforData.timeSend",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("30"),
                          ]),
                          _c("td", [_vm._v("Mã xác thực")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.sysInforData.verificationCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "verificationCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "sysInforData.verificationCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("31"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Có gửi thông báo hết hạn trường cho plus không"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: _vm.sysInforData.schoolTrialStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "schoolTrialStatus",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.schoolTrialStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("32"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Thời gian gửi thông báo hết hạn trường là mấy ngày"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled: !_vm.sysInforData.schoolTrialStatus,
                                },
                                model: {
                                  value: _vm.sysInforData.schoolTrailNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "schoolTrailNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "sysInforData.schoolTrailNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("33"),
                          ]),
                          _c("td", [
                            _vm._v("Có gửi thông báo hết hạn tài khoản không"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: _vm.sysInforData.accountTrialStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "accountTrialStatus",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.accountTrialStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("34"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Thời gian gửi thông báo hết hạn tài khoản là mấy ngày"
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled:
                                    !_vm.sysInforData.accountTrialStatus,
                                },
                                model: {
                                  value: _vm.sysInforData.accountTrailNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "accountTrailNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "sysInforData.accountTrailNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("35"),
                          ]),
                          _c("td", [_vm._v("Ảnh mặc định OneCam")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.oneCamePicture,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "oneCamePicture",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.oneCamePicture",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("36"),
                          ]),
                          _c("td", [_vm._v("Ảnh mặc định Album")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.defaultAlbumPicture,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultAlbumPicture",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultAlbumPicture",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("37"),
                          ]),
                          _c("td", [_vm._v("Chọn hiển thị ảnh album")]),
                          _c("td", [
                            _c("tr", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sysInforData.useAlbumPicture,
                                    expression: "sysInforData.useAlbumPicture",
                                  },
                                ],
                                attrs: { type: "radio", name: "options" },
                                domProps: {
                                  value: "server",
                                  checked: _vm._q(
                                    _vm.sysInforData.useAlbumPicture,
                                    "server"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.sysInforData,
                                      "useAlbumPicture",
                                      "server"
                                    )
                                  },
                                },
                              }),
                              _c("label", [_vm._v("Server")]),
                              _vm._v("  \n\n                "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sysInforData.useAlbumPicture,
                                    expression: "sysInforData.useAlbumPicture",
                                  },
                                ],
                                attrs: { type: "radio", name: "options" },
                                domProps: {
                                  value: "amazon",
                                  checked: _vm._q(
                                    _vm.sysInforData.useAlbumPicture,
                                    "amazon"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.sysInforData,
                                      "useAlbumPicture",
                                      "amazon"
                                    )
                                  },
                                },
                              }),
                              _c("label", [_vm._v("S3 amazon")]),
                              _vm._v("  \n\n                "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sysInforData.useAlbumPicture,
                                    expression: "sysInforData.useAlbumPicture",
                                  },
                                ],
                                attrs: { type: "radio", name: "options" },
                                domProps: {
                                  value: "all",
                                  checked: _vm._q(
                                    _vm.sysInforData.useAlbumPicture,
                                    "all"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.sysInforData,
                                      "useAlbumPicture",
                                      "all"
                                    )
                                  },
                                },
                              }),
                              _c("label", [_vm._v("All")]),
                              _vm._v(" \n\n                "),
                              _vm.sysInforData.useAlbumPicture === "all"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v("Số ngày xem trên server: "),
                                      ]),
                                      _vm._v(" \n                    "),
                                      _c("el-input-number", {
                                        model: {
                                          value:
                                            _vm.sysInforData
                                              .numberDateShowServer,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sysInforData,
                                              "numberDateShowServer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sysInforData.numberDateShowServer",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "tr",
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v("Số luồng chạy: ")]
                                ),
                                _c("el-input-number", {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-top": "5px",
                                  },
                                  model: {
                                    value: _vm.sysInforData.numberThreadAction,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sysInforData,
                                        "numberThreadAction",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sysInforData.numberThreadAction",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "30px" } },
                                  [_vm._v("Số ngày chuyển 1 lần: ")]
                                ),
                                _c("el-input-number", {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-top": "5px",
                                  },
                                  model: {
                                    value: _vm.sysInforData.numberDayTransfer,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sysInforData,
                                        "numberDayTransfer",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sysInforData.numberDayTransfer",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("38"),
                          ]),
                          _c("td", [_vm._v("Link mặc định Home FB")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.defaultLinkHomeFB,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkHomeFB",
                                      $$v
                                    )
                                  },
                                  expression: "sysInforData.defaultLinkHomeFB",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("39"),
                          ]),
                          _c("td", [_vm._v("Link mặc định Home Video")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.defaultLinkHomeVideo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkHomeVideo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultLinkHomeVideo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("40"),
                          ]),
                          _c("td", [
                            _vm._v("Link mặc định Home Website trường"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value:
                                    _vm.sysInforData
                                      .defaultLinkHomeWebsiteSchool,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkHomeWebsiteSchool",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultLinkHomeWebsiteSchool",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("41"),
                          ]),
                          _c("td", [_vm._v("Link mặc định Home Tiktok")]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value: _vm.sysInforData.defaultLinkHomeTiktok,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkHomeTiktok",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultLinkHomeTiktok",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("42"),
                          ]),
                          _c("td", [
                            _vm._v("Link mặc định Home trang Onekids"),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value:
                                    _vm.sysInforData.defaultLinkHomeOneKidsPage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkHomeOneKidsPage",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultLinkHomeOneKidsPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "table-index" }, [
                            _vm._v("43"),
                          ]),
                          _c("td", [
                            _vm._v(
                              "Link mặc định khi điều hướng sang facebook, tiktok trong OneCam "
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "table-action" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", autosize: "" },
                                model: {
                                  value:
                                    _vm.sysInforData
                                      .defaultLinkTransferFacebookOrTikTok,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sysInforData,
                                      "defaultLinkTransferFacebookOrTikTok",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sysInforData.defaultLinkTransferFacebookOrTikTok",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: {
                          type: "success",
                          loading: _vm.loaddingButtonSys,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateSysInforMethod()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "IOS OneKids", name: "onekidsIosTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentIos.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appParentIos, "version", $$v)
                                },
                                expression: "appParentIos.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appParentIos.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appParentIos,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appParentIos.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appParentIos.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appParentIos,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "appParentIos.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentIos.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appParentIos,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "appParentIos.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentIos.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appParentIos, "apiUrl", $$v)
                                },
                                expression: "appParentIos.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(_vm.appParentIos)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              {
                attrs: { label: "Android OneKids", name: "onekidsAndroidTab" },
              },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentAndroid.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appParentAndroid, "version", $$v)
                                },
                                expression: "appParentAndroid.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appParentAndroid.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appParentAndroid,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appParentAndroid.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appParentAndroid.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appParentAndroid,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "appParentAndroid.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentAndroid.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appParentAndroid,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "appParentAndroid.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appParentAndroid.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appParentAndroid, "apiUrl", $$v)
                                },
                                expression: "appParentAndroid.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(
                              _vm.appParentAndroid
                            )
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "IOS Teacher", name: "teacherIosTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherIos.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appTeacherIos, "version", $$v)
                                },
                                expression: "appTeacherIos.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appTeacherIos.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appTeacherIos,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appTeacherIos.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appTeacherIos.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appTeacherIos,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "appTeacherIos.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherIos.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appTeacherIos,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "appTeacherIos.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherIos.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appTeacherIos, "apiUrl", $$v)
                                },
                                expression: "appTeacherIos.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(_vm.appTeacherIos)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              {
                attrs: { label: "Android Teacher", name: "teacherAndroidTab" },
              },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherAndroid.version,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appTeacherAndroid,
                                    "version",
                                    $$v
                                  )
                                },
                                expression: "appTeacherAndroid.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appTeacherAndroid.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appTeacherAndroid,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appTeacherAndroid.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appTeacherAndroid.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appTeacherAndroid,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "appTeacherAndroid.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherAndroid.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appTeacherAndroid,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "appTeacherAndroid.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appTeacherAndroid.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appTeacherAndroid, "apiUrl", $$v)
                                },
                                expression: "appTeacherAndroid.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(
                              _vm.appTeacherAndroid
                            )
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "IOS Plus", name: "plusIosTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusIos.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appPlusIos, "version", $$v)
                                },
                                expression: "appPlusIos.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appPlusIos.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appPlusIos,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appPlusIos.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appPlusIos.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.appPlusIos, "compulsory", $$v)
                                  },
                                  expression: "appPlusIos.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusIos.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appPlusIos, "updateContent", $$v)
                                },
                                expression: "appPlusIos.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusIos.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appPlusIos, "apiUrl", $$v)
                                },
                                expression: "appPlusIos.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "15px 0" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(_vm.appPlusIos)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "Android Plus", name: "plusAndroidTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusAndroid.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appPlusAndroid, "version", $$v)
                                },
                                expression: "appPlusAndroid.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appPlusAndroid.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appPlusAndroid,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "appPlusAndroid.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.appPlusAndroid.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.appPlusAndroid,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "appPlusAndroid.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusAndroid.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.appPlusAndroid,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "appPlusAndroid.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.appPlusAndroid.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.appPlusAndroid, "apiUrl", $$v)
                                },
                                expression: "appPlusAndroid.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      float: "right",
                      margin: "15px 0",
                      border: "0",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(
                              _vm.appPlusAndroid
                            )
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "IOS OneCam", name: "iosOneCamTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamIos.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oneCamIos, "version", $$v)
                                },
                                expression: "oneCamIos.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.oneCamIos.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.oneCamIos,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "oneCamIos.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.oneCamIos.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.oneCamIos, "compulsory", $$v)
                                  },
                                  expression: "oneCamIos.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamIos.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oneCamIos, "updateContent", $$v)
                                },
                                expression: "oneCamIos.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamIos.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oneCamIos, "apiUrl", $$v)
                                },
                                expression: "oneCamIos.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      float: "right",
                      margin: "15px 0",
                      border: "0",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(_vm.oneCamIos)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "Android OneCam", name: "oneCamAndroidTab" } },
              [
                _c(
                  "table",
                  { staticClass: "table-data", attrs: { border: "" } },
                  [
                    _c("thead", { staticClass: "table-header" }, [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [
                          _vm._v("STT"),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Nội dung thiết lập"),
                        ]),
                        _c("td", { staticClass: "table-action" }, [
                          _vm._v("Giá trị thiết lập"),
                        ]),
                      ]),
                    ]),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("1")]),
                        _c("td", [_vm._v("Tên phiên bản")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamAndroid.version,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oneCamAndroid, "version", $$v)
                                },
                                expression: "oneCamAndroid.version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("2")]),
                        _c("td", [_vm._v("Có thông báo Update hay Không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.oneCamAndroid.versionUpdate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.oneCamAndroid,
                                      "versionUpdate",
                                      $$v
                                    )
                                  },
                                  expression: "oneCamAndroid.versionUpdate",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("3")]),
                        _c("td", [_vm._v("Có bắt buộc Update hay không?")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.oneCamAndroid.compulsory,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.oneCamAndroid,
                                      "compulsory",
                                      $$v
                                    )
                                  },
                                  expression: "oneCamAndroid.compulsory",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("Có"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("Không"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("4")]),
                        _c("td", [_vm._v("Nội dung trong thông báo Update")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamAndroid.updateContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.oneCamAndroid,
                                    "updateContent",
                                    $$v
                                  )
                                },
                                expression: "oneCamAndroid.updateContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "table-index" }, [_vm._v("5")]),
                        _c("td", [_vm._v("Link API")]),
                        _c(
                          "td",
                          { staticClass: "table-action" },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", autosize: "" },
                              model: {
                                value: _vm.oneCamAndroid.apiUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oneCamAndroid, "apiUrl", $$v)
                                },
                                expression: "oneCamAndroid.apiUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      float: "right",
                      margin: "15px 0",
                      border: "0",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success", loading: _vm.loaddingButton },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppVersionMethod(_vm.oneCamAndroid)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-circle-check" }),
                        _c("span", [_vm._v("Lưu")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }